import React from 'react'
import css from 'clsx'
import styles from './Link.module.css'

export type Props = React.HTMLAttributes<HTMLAnchorElement> &
  React.PropsWithChildren<{
    href: string
    size?: 12 | 14 | 16 | 18 | 20 | 22 | 24
    family?: 'nova-escola' | 'lora'
    bold?: boolean
    italic?: boolean
    color?: 'azul' | 'preto' | 'branco'
    className?: string
  }>
const Link: React.FC<Props> = ({
  href,
  size = 16,
  family = 'nova-escola',
  color = 'azul',
  className,
  bold,
  italic,
  children,
  ...props
}) => (
  <a
    className={css(
      { 'eva-text-bold': bold, 'eva-text-italic': italic },
      size && `eva-font-${size}`,
      `eva-link-${color}`,
      styles[`font-${family}`],
      className && className
    )}
    href={href}
    target='_blank'
    rel='noreferrer'
    {...props}
  >
    {children}
  </a>
)

export default Link
