import React from 'react'
import { colors } from '../colors'
import css from 'clsx'
import styles from './Text.module.css'

export type Props = React.HTMLAttributes<HTMLParagraphElement> &
  React.PropsWithChildren<{
    size?: 12 | 14 | 16 | 18 | 20 | 22 | 24
    family?: 'nova-escola' | 'lora'
    bold?: boolean
    italic?: boolean
    color?: keyof typeof colors
    align?: 'center' | 'right' | 'left'
    className?: string
  }>

const Text: React.FC<Props> = ({
  size = 16,
  family = 'nova-escola',
  color = 'peixe-boi',
  align = 'left',
  className,
  bold,
  italic,
  children,
  ...props
}) => {
  return (
    <p
      className={css(
        { 'eva-text-bold': bold, 'eva-text-italic': italic },
        size && `eva-font-${size}`,
        `eva-fg-${color}`,
        styles[`font-${family}`],
        styles[`text-align-${align}`],
        className && className
      )}
      {...props}
    >
      {children}
    </p>
  )
}

export default Text
