import React from 'react'
import { colors } from '../colors'
import css from 'clsx'

export type Props = React.HTMLAttributes<HTMLParagraphElement> &
  React.PropsWithChildren<{
    color?: keyof typeof colors
    size?: 'small' | 'medium' | 'large' | 'xlarge'
    full?: boolean
    disabled?: boolean
    type?: string
    align?: 'center' | 'right' | 'left'
    testId?: string
    onClick: () => void
  }>

const Button: React.FC<Props> = ({
  color = 'acai',
  size = 'medium',
  type = 'solid',
  align = 'left',
  testId = size,
  onClick,
  full,
  disabled,
  children
}) => {
  return (
    <button
      data-testid={`button-${testId}`}
      type='button'
      className={css(
        {
          'eva-btn-full': full,
          'eva-margin-center': align === 'center',
          'eva-float-right': align === 'right',
          'eva-btn-disabled': disabled
        },
        `eva-btn-${type}`,
        !disabled && `eva-btn-${color}`,
        `eva-btn-${size}`
      )}
      disabled={disabled}
      aria-disabled={disabled}
      onClick={onClick}
    >
      {children}
    </button>
  )
}

export default Button
