import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import styles from './Collapsible.module.css'

export type Props = React.PropsWithChildren<{
  label: string
  variant?: 'default' | 'box'
  classNameContent?: string
  classNameLabel?: string
  onOpen?: () => void
  onClose?: () => void
}>

const Collapsible: React.FC<Props> = ({
  classNameContent,
  classNameLabel,
  variant = 'default',
  label,
  onOpen,
  onClose,
  children
}) => {
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (open) {
      onOpen && onOpen()
    } else {
      onClose && onClose()
    }
  }, [open])

  return (
    <div
      className={clsx(styles.root, {
        [styles.open]: open,
        [styles.boxVariant]: variant === 'box'
      })}
    >
      <button
        data-testid='collapsible-header'
        className={clsx(styles.title, classNameLabel && classNameLabel)}
        onClick={() => {
          setOpen((o) => !o)
        }}
      >
        <span data-testid='label'>{label}</span>{' '}
        <i
          className={clsx('fas fa-sort-down', styles.icon, {
            [styles.open]: open
          })}
        />
      </button>
      {open && (
        <div
          className={clsx(styles.content, classNameContent && classNameContent)}
        >
          {children}
        </div>
      )}
    </div>
  )
}

export default Collapsible
